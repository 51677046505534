import { render, staticRenderFns } from "./proc-tree.vue?vue&type=template&id=5c9873e6"
import script from "./proc-tree.vue?vue&type=script&lang=js"
export * from "./proc-tree.vue?vue&type=script&lang=js"
import style0 from "./proc-tree.vue?vue&type=style&index=0&id=5c9873e6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VTreeview})
